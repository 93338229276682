


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  TITLE_HOLD_TYPE,
  ANOTHER_PROPERTY_TYPE,
  CITIZEN_SHIP,
  DOWN_PAYMENT_TYPE
} from "@/constants";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { Declaration } from "@/models/pos/declaration.model";
import Datepicker from "vuejs-datepicker";

@Component({
  components: {
    Datepicker
  }
})
export default class DeclarationComponent extends mixins(QuestionnaireMixin) {
  @Prop()
  public history;
  public titleHoldType = TITLE_HOLD_TYPE;
  public anotherPropertyType = ANOTHER_PROPERTY_TYPE;
  public citizenShip = CITIZEN_SHIP;
  public downpaymentType = DOWN_PAYMENT_TYPE;
  public declarationDetail = null;
  public dotLoader = false;
  public checkError = false;
  public declaration = new Declaration();

  getParams(type: number) {
    /**
     * Whether any gifts received
     */
    let cond1 = this.declarationDetail.receiveGift == "true";

    /**
     * Whether coBorrower & employed for more than 3 years;
     */
    let cond2 = this.isLessThanTwoYears(
      this.declarationDetail.borrowerInfo[this.questionHistory.index]
    );
    let cond3 = this.questionHistory.userType == "coBorrower";
    let cond4 = this.declarationDetail.borrowerInfo[this.questionHistory.index]
      .currentEmployment[0].isRetired;

    switch (type) {
      case 1:
        if (cond3 && (!cond2 || cond4)) {
          return 2;
        }

        if (cond3 && cond2) {
          return 4;
        }

        if (!cond3 && cond1) {
          return 4;
        }

        if (!cond3 && !cond1) {
          return 3;
        }
        break;

      case 2:
        if (cond3 && cond4) return 1;

        if (cond3 && !cond2) {
          return 3;
        }

        if (cond3 && cond2) {
          return 5;
        }

        if (!cond3 && cond1) {
          return 5;
        }

        if (!cond3 && !cond1) {
          return 4;
        }
        break;

      case 3:
        return cond3 ? "II" : "LAP";
    }
  }

  clearDeclarationData() {
    const i = this.questionHistory.index;
    if (
      this.declarationDetail.declaration[i].everTransferOftitleDuetoLoan
        .transfer == "false"
    ) {
      this.declarationDetail.declaration[
        i
      ].everTransferOftitleDuetoLoan.explanation = null;
    }
    if (
      this.declarationDetail.declaration[i].borrowedForDownpayment
        .hasBorrowed == "false"
    ) {
      this.declarationDetail.declaration[
        i
      ].borrowedForDownpayment.explanation = 0;
    }
    if (
      this.declarationDetail.declaration[i].propertyType.isPrimaryResidence ==
      "false"
    ) {
      this.declarationDetail.declaration[i].propertyType.ownershipInPast =
        "false";
      this.declarationDetail.declaration[i].propertyType.typeOfProperty = null;
      this.declarationDetail.declaration[
        i
      ].propertyType.typeOfPropertyTitle = null;
    }
    if (
      this.declarationDetail.declaration[i].propertyType.ownershipInPast ==
      "false"
    ) {
      this.declarationDetail.declaration[i].propertyType.typeOfProperty = null;
      this.declarationDetail.declaration[
        i
      ].propertyType.typeOfPropertyTitle = null;
    }
    if (
      this.declarationDetail.declaration[i].outstandingJudgements
        .hasOutstandingJudgements == "false"
    ) {
      this.declarationDetail.declaration[
        i
      ].outstandingJudgements.explanation = null;
    }
    if (
      this.declarationDetail.declaration[i].bankruptcyDeclaration
        .haveDeclaredBankruptcy == "false"
    ) {
      this.declarationDetail.declaration[
        i
      ].bankruptcyDeclaration.typesOfBankruptcy = [];
    }
    if (
      this.declarationDetail.declaration[i].conveyedTitleToAnyProperty
        .haveConveyedTitleToAnyProperty == "false"
    ) {
      this.declarationDetail.declaration[
        i
      ].conveyedTitleToAnyProperty.explanation = null;
    }
    if (
      this.declarationDetail.declaration[i].partyInLawsuit.isPartyInLawsuit ==
      "false"
    ) {
      this.declarationDetail.declaration[i].partyInLawsuit.explanation = null;
    }
    if (
      this.declarationDetail.declaration[i].federalDebt.hasFederalDebt ==
      "false"
    ) {
      this.declarationDetail.declaration[i].federalDebt.explanation = null;
    }
    if (
      this.declarationDetail.declaration[i].childAlimonySeperateSupport
        .isChildAlimonySeperateSupport == "false"
    ) {
      this.declarationDetail.declaration[
        i
      ].childAlimonySeperateSupport.explanation = null;
    }
    if (this.declarationDetail.declaration[i].coSigner.isCoSigner == "false") {
      this.declarationDetail.declaration[i].coSigner.explanation = null;
    }
  }
  public async saveDeclarationDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let formValid = await this.$validator.validateAll("DCL1");
      if (!formValid) {
        this.checkError = true;
        this.$store.state.wemloLoader = false;
        return;
      }
      this.checkError = false;
      let res = await Axios.post(
        BASE_API_URL + "pos/saveDeclarationDetail",
        {
          declarationDetail: this.declarationDetail,
          loanTxnId: this.$route.query.loanTxnId
        });
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }
  public async getDeclarationDetail() {
    try {
      this.dotLoader = false;
      let res = await Axios.get(BASE_API_URL + "pos/getDeclarationDetail", {
        params: {
          loanTxnId: this.$route.query.loanTxnId
        }
      });
      this.dotLoader = false;
      this.declarationDetail = res.data;
      await this.updateDeclationData();
    } catch (error) {
      this.dotLoader = false;
    }
  }

/*******************************************************************************************************************************************************************************
 *                This Function is used for Exceptional Cases. (If Declaration is null or index of declaration of null).    
 *******************************************************************************************************************************************************************************/
  public async updateDeclationData() {
    try {
      let declaration = JSON.parse(JSON.stringify(this.declaration.borrowerInfo[0]));
      if(!this.declarationDetail.declaration){
        this.declarationDetail.declaration = [];
        this.declarationDetail.borrowerInfo.forEach(element => {this.declarationDetail.declaration.push(declaration);});
      }
      let haveDecleration = this.declarationDetail.declaration.filter(a=> !a);
      if(haveDecleration.length>0){
        this.declarationDetail.declaration.forEach((el, index)=>{
          if(!el)
            this.declarationDetail.declaration[index] = declaration;
        });
      }
      this.declarationDetail = JSON.parse(JSON.stringify(this.declarationDetail));
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.questionHistory = this.history;
    await this.getDeclarationDetail();
  }
}
